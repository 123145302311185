import './App.css';
import React from 'react';
import Plot from 'react-plotly.js';


import small15 from './custom_data/winds/without-foul/winds_19256_simulation_without_fouling_15_knots_aft_11.8_fore_10.7';
import small16 from './custom_data/winds/without-foul/winds_19256_simulation_without_fouling_16_knots_aft_11.8_fore_10.7';
import small17 from './custom_data/winds/without-foul/winds_19256_simulation_without_fouling_17_knots_aft_11.8_fore_10.7';
import small18 from './custom_data/winds/without-foul/winds_19256_simulation_without_fouling_18_knots_aft_11.8_fore_10.7';

import { TbRotateDot } from 'react-icons/tb';

class CustomWinds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data:small15,
      rotate:false,
      fouling:false,
      eye: {
        eye: {
          x: 1.5,
          y: 1.5,
          z: 1.5
        }
      },
      speed:15,
      draft:"small"
    };
  }

  componentDidMount(){
    this.toggleRotate()
  }


  toggleRotate = () => {
    if(!this.state.rotate){
      this.setState({
        rotate: true,
      })

      this.myInterval = setInterval(()=>{
        this.rotate(Math.PI / 180)
      }, 50);

    }
    else{
      this.setState({
        rotate: false,
      })
      clearInterval(this.myInterval)
    }

  }

  toggleSpeed = () => {
    if(this.state.speed === 18){
      this.setState({
        speed:15
      })
      this.generateData(15, this.state.draft)
    }
    else{
      this.setState({
        speed:this.state.speed+1
      })
      this.generateData(this.state.speed+1, this.state.draft)
    }
  }

  toggleDraft = () => {
    // if(this.state.draft === "small"){
    //   this.setState({
    //     draft:"big"
    //   })
    //   this.generateData(this.state.speed, "big")
    // }
    // else{
    //   this.setState({
    //     draft:"small"
    //   })
    //   this.generateData(this.state.speed, "small")
    // }
  }

  generateData = (speed, draft) =>{
    let answer;
    if (speed === 15 & draft==="small") {
      answer = !this.state.fouling?small15:small15;
    } else if (speed === 15 & draft==="big") {
      answer = !this.state.fouling?small15:small15;
    } else if (speed === 16 & draft==="small") {
      answer = !this.state.fouling?small16:small16;
    } else if (speed === 16 & draft==="big") {
      answer = !this.state.fouling?small16:small16;
    } else if (speed === 17 & draft==="small") {
      answer = !this.state.fouling?small17:small17;
    } else if (speed === 17 & draft==="big") {
      answer = !this.state.fouling?small17:small17;
    } else if (speed === 18 & draft==="small") {
      answer = !this.state.fouling?small18:small18;
    } else if (speed === 18 & draft==="big") {
      answer = !this.state.fouling?small18:small18;
    }
    this.setState({
      data:answer
    })
  }

  toggleFouling = () => {
      this.setState({
        fouling: !this.state.fouling
      })
      this.generateData(this.state.speed, this.state.draft)
  }

  dataGenerator = () => {
    return(
      [{
        x: this.state.data[0].x,
        y: this.state.data[0].y,
        z: this.state.data[0].z,
        type: 'surface',
        hovertemplate:
                            ('Wind speed: %{x:.1f}kts' +
                            '<br>Wind angle: %{y:.0f}º<br>' +
                            'Power required: %{z:.2f}MW' +
                            '<extra></extra>')
                            ,
        showscale: false,
        colorscale: !this.state.fouling ? [
          ['0.0', '#47B5D6'],
          ['0.3', '#3F6FEF'],
          ['0.6', '#9855DD'],
          ['1.0', '#C91A62']
        ] :
        [
          ['0.0', '#47B5D6'],
          ['0.3', '#633fef'],
          ['0.6', '#c855dd'],
          ['1.0', '#f00365']
        ]
      }]
    )
  }

  rotate = (angle) => {
    var eye0 = this.state.eye.eye;
    var rtz = this.xyz2rtz(eye0);
    rtz.t += angle;
    var eye1 = this.rtz2xyz(rtz);
    this.setState({
      eye: {
        eye:eye1
      },
    });
  }

  xyz2rtz = (xyz) => {
    return {
      r: Math.sqrt(xyz.x * xyz.x + xyz.y * xyz.y),
      t: Math.atan2(xyz.y, xyz.x),
      z: xyz.z
    };
  }

  rtz2xyz = (rtz) => {
    return {
      x: rtz.r * Math.cos(rtz.t),
      y: rtz.r * Math.sin(rtz.t),
      z: rtz.z
    };
  }

  stopRotate = () => {
    clearInterval(this.myInterval)
    this.setState({
      rotate: false,
    })
  }

  render() {
  return (
    <div className="App">
      <header className="App-header">
      <div className="button-bar">
      </div>
      <div id="goer">
        <Plot
         data={this.dataGenerator()}
         config={{ displayModeBar:false}}
         onClick={this.stopRotate}
         layout={ {
           plot_bgcolor:"transparent",
           paper_bgcolor:"transparent",
           width: 900, height: 600,
           showlegend: false,
           scene:{
            	xaxis: {
                title: "Wind speed",
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
            	 backgroundcolor: "rgb(200, 200, 230)",
            	 gridcolor: "white",
            	 showbackground: false,
            	 zerolinecolor: "rgb(255, 255, 255)",
            	},
              yaxis: {
                title: "Apparent angle",
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
               backgroundcolor: "rgb(230, 200,230)",
               gridcolor: "white",
               showbackground: false,
               zerolinecolor: "rgb(255, 255, 255)"
              },
              zaxis: {
                title: "Power required",
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
               backgroundcolor: "rgb(230, 230,200)",
               gridcolor: "rgb(255, 255, 255)",
               showbackground: false,
               zerolinecolor: "rgb(255, 255, 255)",
               autorange: false,
               range: [2,12],
               type: 'linear'
             },
             camera: this.state.eye
           },
            margin: {
              l: 0,
              r: 0,
              b: 0,
              t: 0,
              pad: 40
            }
         } }
       />
       </div>
       <div className="info-bar">
          <p><TbRotateDot style={{cursor:'pointer'}} onClick={this.toggleRotate} size={50}/></p>
          <p><strong>Vessel Type:</strong> Vehicle Carrier</p>
          <p><strong>Length:</strong> 199.9m</p>
          <p><strong>Speed through water:</strong> <span className="speedToggler" onClick={this.toggleSpeed}>{this.state.speed + " kts"}</span></p>
          <p><strong>Draft:</strong> <span className="speedToggler" onClick={this.toggleDraft}>{this.state.draft==="big"?"12.2m, 12.8m" :"10.7m, 11.8m"}</span></p>
          <p><strong>Model Type:</strong> <span className="greenText">Wind speed vs Wind angle vs Power</span></p>
       </div>

      </header>
    </div>
  );
}
}


export default CustomWinds;
