import video from './videos/gif2_v2.mp4';
import videoJap from './videos/gif2_v2_jp.mp4';
import './App.css';
import React from 'react';
import localiseText from './text.json';
import { MdOutlineLanguage } from 'react-icons/md';

class Impact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount(){

  }

  render() {
  return (
    <div className="App">
      <header className="App-header">

      {this.props.lang==="EN" &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>
            <source id="map" src={video} type="video/mp4"></source>
        </video>
      }
      {this.props.lang==="JAP" &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>
            <source id="map" src={videoJap} type="video/mp4"></source>
        </video>
      }
      <p><strong>{localiseText[this.props.lang]["Getting the voyage plan right makes an instant impact to consumption"]}</strong></p>
      <div style={{color: 'white', cursor:'pointer'}} id="toggleLanguage" onClick={this.props.toggleLang}><MdOutlineLanguage size={35}/></div>
      </header>
    </div>
  );
}
}


export default Impact;
