import './App.css';
import React from 'react';
import Plot from 'react-plotly.js';
import eight_knots_data_fouled from './data/currents_9000_simulation_with_foul_8_knots.json';
import ten_knots_data_fouled from './data/currents_9000_simulation_with_foul_10_knots.json';
import twelve_knots_data_fouled from './data/currents_9000_simulation_with_foul_12_knots.json';
import fourteen_knots_data_fouled from './data/currents_9000_simulation_with_foul_14_knots.json';
import eight_knots_data from './data/currents_9000_simulation_without_8_knots.json';
import ten_knots_data from './data/currents_9000_simulation_without_10_knots.json';
import twelve_knots_data from './data/currents_9000_simulation_without_12_knots.json';
import fourteen_knots_data from './data/currents_9000_simulation_without_14_knots.json';
import { TbRotateDot } from 'react-icons/tb';
import localiseText from './text.json';
import { MdOutlineLanguage } from 'react-icons/md';

class Currents extends React.Component {

  constructor(props) {
    super(props);
    this.dataFouled = {8:eight_knots_data_fouled, 10:ten_knots_data_fouled, 12:twelve_knots_data_fouled, 14:fourteen_knots_data_fouled}
    this.dataClean = {8:eight_knots_data, 10:ten_knots_data, 12:twelve_knots_data, 14:fourteen_knots_data}
    this.state = {
      rotate:false,
      speed:12,
      fouling:false,
      eye: {
        eye: {
          x: 1.5,
          y: 1.5,
          z: 1.5
        }
      },
    };
  }

  componentDidMount(){
    this.toggleRotate()
  }

  toggleRotate = () => {
    if(!this.state.rotate){
      this.setState({
        rotate: true,
      })

      this.myInterval = setInterval(()=>{
        this.rotate(Math.PI / 180)
      }, 50);

    }
    else{
      this.setState({
        rotate: false,
      })
      clearInterval(this.myInterval)
    }

  }

  rotate = (angle) => {
    var eye0 = this.state.eye.eye;
    var rtz = this.xyz2rtz(eye0);
    rtz.t += angle;
    var eye1 = this.rtz2xyz(rtz);
    this.setState({
      eye: {
        eye:eye1
      },
    });
  }

  toggleSpeed = () => {
    if(this.state.speed === 14){
      this.setState({
        speed:8
      })
    }
    else{
      this.setState({
        speed:this.state.speed+2
      })
    }
  }

  xyz2rtz = (xyz) => {
    return {
      r: Math.sqrt(xyz.x * xyz.x + xyz.y * xyz.y),
      t: Math.atan2(xyz.y, xyz.x),
      z: xyz.z
    };
  }

  rtz2xyz = (rtz) => {
    return {
      x: rtz.r * Math.cos(rtz.t),
      y: rtz.r * Math.sin(rtz.t),
      z: rtz.z
    };
  }

  stopRotate = () => {
    clearInterval(this.myInterval)
    this.setState({
      rotate: false,
    })
  }

  toggleFouling = () => {
      this.setState({
        fouling: !this.state.fouling
      })
  }

  dataGenerator = () => {
    return(
      [{
        x: this.state.fouling ? this.dataFouled[this.state.speed][0].x : this.dataClean[this.state.speed][0].x,
        y: this.state.fouling ? this.dataFouled[this.state.speed][0].y : this.dataClean[this.state.speed][0].y,
        z: this.state.fouling ? this.dataFouled[this.state.speed][0].z : this.dataClean[this.state.speed][0].z,
        type: 'surface',
        hovertemplate: this.props.lang === "EN" ?
                            ('Current speed: %{x:.1f}kts' +
                            '<br>Current angle: %{y:.0f}º<br>' +
                            'Power required: %{z:.2f}MW' +
                            '<extra></extra>'):
                            ('のギャレー: %{x:.1f}kts' +
                            '<br>を取タイプ見本帳を: %{y:.0f}º<br>' +
                            'をルしてタイプ見本帳を: %{z:.2f}MW' +
                            '<extra></extra>')
                            ,
        showscale: false,
        colorscale: !this.state.fouling ? [
          ['0.0', '#47B5D6'],
          ['0.3', '#3F6FEF'],
          ['0.6', '#9855DD'],
          ['1.0', '#C91A62']
        ] :
        [
          ['0.0', '#47B5D6'],
          ['0.3', '#633fef'],
          ['0.6', '#c855dd'],
          ['1.0', '#f00365']
        ]
      }]
    )
  }

  render() {
  return (
    <div className="App">
      <header className="App-header">
      <div className="button-bar">
      </div>
      <div id="goer">
        <Plot
         data={this.dataGenerator()}
         config={{ displayModeBar:false}}
         onClick={this.stopRotate}
         layout={ {
           plot_bgcolor:"transparent",
           paper_bgcolor:"transparent",
           width: 900, height: 600,
           showlegend: false,
           scene:{
            	xaxis: {
                title: localiseText[this.props.lang]["Current speed"],
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
            	 backgroundcolor: "rgb(200, 200, 230)",
            	 gridcolor: "white",
            	 showbackground: false,
            	 zerolinecolor: "rgb(255, 255, 255)",
            	},
              yaxis: {
                title: localiseText[this.props.lang]["Apparent angle"],
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
               backgroundcolor: "rgb(230, 200,230)",
               gridcolor: "white",
               showbackground: false,
               zerolinecolor: "rgb(255, 255, 255)",
              },
              zaxis: {
                title: localiseText[this.props.lang]["Power required"],
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
               backgroundcolor: "rgb(230, 230,200)",
               gridcolor: "rgb(255, 255, 255)",
               showbackground: false,
               zerolinecolor: "rgb(255, 255, 255)",
               autorange: false,
               range: [0, 10],
               type: 'linear'
             },
             camera: this.state.eye
           },
            margin: {
              l: 0,
              r: 0,
              b: 0,
              t: 0,
              pad: 40
            }
         } }
       />
       </div>
       <div className="info-bar">
          <p><TbRotateDot style={{cursor:'pointer'}} onClick={this.toggleRotate} size={50}/></p>
          <p><strong>{localiseText[this.props.lang]["Vessel Name:"]}</strong> {localiseText[this.props.lang]["MV Pioneer"]}</p>
          <p><strong>{localiseText[this.props.lang]["Vessel Type:"]}</strong> {localiseText[this.props.lang]["Tanker"]}</p>
          <p><strong>{localiseText[this.props.lang]["Length:"]}</strong> {localiseText[this.props.lang]["183m"]}</p>
          <p><strong>{localiseText[this.props.lang]["Speed through water:"]}</strong> <span className="speedToggler" onClick={this.toggleSpeed}>{this.state.speed + " kts"}</span></p>
          <p><strong>{localiseText[this.props.lang]["Draft:"]}</strong> {localiseText[this.props.lang]["11m, 11m"]}</p>
          <p><strong>{localiseText[this.props.lang]["Model Type:"]}</strong> <span className="greenText">{localiseText[this.props.lang]["Current speed vs Current angle vs Power"]}</span></p>
       </div>
       <div style={{color: 'white', cursor:'pointer'}} id="toggleLanguage" onClick={this.props.toggleLang}><MdOutlineLanguage size={35}/></div>
       <div style={{cursor:'pointer'}} id="toggleFouling" onClick={this.toggleFouling}>{localiseText[this.props.lang][this.state.fouling?"Remove fouling":"Add fouling"]}</div>

      </header>
    </div>
  );
}
}


export default Currents;
