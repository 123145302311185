import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import 'rmc-picker/assets/index.css';
import Picker from 'rmc-picker/lib/Picker';
import logo from './logo.svg';
import localiseText from './text.json';
import netlifyIdentity from './netlify-identity';
import Intro from './Intro';
import Winds from './Winds';
import Solution from './Solution';
import Impact from './Impact';
import Currents from './Currents';
import Map from './Map';
import './index.css';
import CustomWinds from './custom_winds';
import CustomCurrents from './custom_currents';
import { BsFullscreen } from 'react-icons/bs';

netlifyIdentity.init();

const Wrapper = () => {
  const [lang, setLang] = useState('EN');
  const [customValue, setCustomValue] = useState(1);
  const [valueopp, setValueopp] = useState(1);
  const onChange = (valuex) => {
    setValueopp(valuex);
  };

  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };


useEffect(() => {

  const handleLogin = () => {
    navigateTo('/');
  };

  const handleLogout = () => {
    navigateTo('/');
  };

  const handleKeyDown = (event) => {
    if (['ArrowRight', 'ArrowDown', 'Enter'].includes(event.key)) {
      if (valueopp < 7) {
        setValueopp((prevValue) => prevValue + 1);
      }
    } else if (['ArrowUp', 'ArrowLeft'].includes(event.key)) {
      if (valueopp > 1) {
        setValueopp((prevValue) => prevValue - 1);
      }
    }
  };

  const listener = netlifyIdentity.on('login', handleLogin);
  const listener2 = netlifyIdentity.on('logout', handleLogout);

  document.addEventListener('keydown', handleKeyDown);

  return () => {
    if (typeof listener === 'function') {
      listener();
    }
    if (typeof listener2 === 'function') {
      listener2();
    }
    document.removeEventListener('keydown', handleKeyDown);
  };
});

  const toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  const toggleLang = () => {
    setLang((prevLang) => (prevLang === 'EN' ? 'JAP' : 'EN'));
  };

  const toggleModel = (model) => {
    setCustomValue(model);
  };


  const user = netlifyIdentity.currentUser();

  if(user){
  return (
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div>
              <div className="siteHeader">
                <div id="logobox">
                  <img src={logo} alt="DeepSea" id="dslogo" />
                  <button className="logout" onClick={() => netlifyIdentity.open()}>
                    {user ? 'Logout' : 'Login'}
                  </button>
                  <button className="visualiserButtom" onClick={() => navigateTo('/')}>
                    Visualiser
                  </button>
                  <button className="visualiserButtom" onClick={() => navigateTo('/custom')}>
                    Custom
                  </button>
                </div>
                <Picker selectedValue={valueopp} onValueChange={onChange}>
                  <Picker.Item value={1} key="intro">
                    <span className="noSelect">
                      1. {localiseText[lang]['Intro']}
                    </span>
                  </Picker.Item>
                  <Picker.Item value={2} key="map">
                    <span className="noSelect">
                      2. {localiseText[lang]['The problem']}
                    </span>
                  </Picker.Item>
                  <Picker.Item value={3} key="wind">
                    <span className="noSelect">
                      3. {localiseText[lang]['Wind model']}
                    </span>
                  </Picker.Item>
                  <Picker.Item value={4} key="currents">
                    <span className="noSelect">
                      4. {localiseText[lang]['Current model']}
                    </span>
                  </Picker.Item>
                  <Picker.Item value={5} key="solution">
                    <span className="noSelect">
                      5. {localiseText[lang]['Finding the solution']}
                    </span>
                  </Picker.Item>
                  <Picker.Item value={6} key="impact">
                    <span className="noSelect">
                      6. {localiseText[lang]['The impact']}
                    </span>
                  </Picker.Item>
                </Picker>
              </div>
              {valueopp === 1 && <Intro lang={lang} toggleLang={toggleLang} />}
              {valueopp === 2 && <Map lang={lang} toggleLang={toggleLang} />}
              {valueopp === 3 && <Winds lang={lang} toggleLang={toggleLang} />}
              {valueopp === 4 && <Currents lang={lang} toggleLang={toggleLang} />}
              {valueopp === 5 && <Solution lang={lang} toggleLang={toggleLang} />}
              {valueopp === 6 && <Impact lang={lang} toggleLang={toggleLang} />}
            </div>
          }
        />
        {user && (
          <Route
            exact
            path="/custom"
            element={
              <div>
                <div className="siteHeader">
                  <div id="logobox">
                    <img src={logo} alt="DeepSea" id="dslogo" />
                    <p id="vesselName_top">HOEGH // HOEGH TRACER</p>
                    <button className="logout" onClick={() => netlifyIdentity.open()}>
                      Logout
                    </button>
                    <button className="visualiserButtom" onClick={() => navigateTo('/')}>
                      Visualiser
                    </button>
                    <button className="visualiserButtom" onClick={() => navigateTo('/custom')}>
                      Custom
                    </button>
                    <div className="selectModelWrapper">
                      <div
                        className="selectModelLeftButton"
                        style={{
                          backgroundColor: customValue === 1 ? '#00FFFF' : 'transparent',
                          color: customValue === 1 ? 'black' : '#00FFFF',
                        }}
                        onClick={() => toggleModel(1)}
                      >
                        Wind models
                      </div>
                      <div
                        className="selectModelRightButton"
                        style={{
                          backgroundColor: customValue === 1 ? 'transparent' : '#00FFFF',
                          color: customValue === 1 ? '#00FFFF' : 'black',
                        }}
                        onClick={() => toggleModel(2)}
                      >
                        Current models
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {customValue === 1 && <CustomWinds />}
                  {customValue === 2 && <CustomCurrents />}
                  <BsFullscreen className="toggleFS" style={{cursor:'pointer'}} onClick={()=>toggleFullScreen()} size={30}/>
                </div>
              </div>
            }
          />
        )}
      </Routes>
  )}
  else{
    return(
      <div>
      <div className="siteHeader">
        <div id="logobox">
          <img src={logo} alt="DeepSea" id="dslogo" />
        </div>
      </div>
      <button className="login_primary" onClick={() => netlifyIdentity.open()}>
        Login to the DeepSea visualiser
      </button>
      </div>
    )
  }
};


const App = () => {
  return (
    <BrowserRouter>
      <Wrapper />
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
