import './App.css';
import React from 'react';
import Plot from 'react-plotly.js';
import plotData1 from './plotData1.json';
import plotData2 from './plotData2.json';
import localiseText from './text.json';
import { BsFullscreen } from 'react-icons/bs';
import { MdOutlineLanguage } from 'react-icons/md';

class Intro extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: datalist1,
      rotate:false,
      eye: {
        eye: {
          x: 1.5,
          y: 1.5,
          z: 1.5
        }
      },
    };
  }

  componentDidMount(){
    this.toggleRotate()
  }

  changeData = () => {
    this.setState({
      data: datalist2,
    });
  }

  toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  toggleRotate = () => {
    if(!this.state.rotate){
      this.setState({
        rotate: true,
      })

      this.myInterval = setInterval(()=>{
        this.rotate(Math.PI / 180)
      }, 50);

    }
    else{
      this.setState({
        rotate: false,
      })
      clearInterval(this.myInterval)
    }

  }

  rotate = (angle) => {
    var eye0 = this.state.eye.eye;
    var rtz = this.xyz2rtz(eye0);
    rtz.t += angle;
    var eye1 = this.rtz2xyz(rtz);
    this.setState({
      eye: {
        eye:eye1
      },
    });
  }

  xyz2rtz = (xyz) => {
    return {
      r: Math.sqrt(xyz.x * xyz.x + xyz.y * xyz.y),
      t: Math.atan2(xyz.y, xyz.x),
      z: xyz.z
    };
  }

  rtz2xyz = (rtz) => {
    return {
      x: rtz.r * Math.cos(rtz.t),
      y: rtz.r * Math.sin(rtz.t),
      z: rtz.z
    };
  }


  render() {
  return (
    <div className="App">
      <header className="App-header">
      <div className="button-bar">
      </div>
      <div id="goer">
        <Plot
         data={this.state.data}
         config={{ displayModeBar:false}}
         layout={ {
           plot_bgcolor:"transparent",
           paper_bgcolor:"transparent",
           width: 400, height: 400,
           showlegend: false,
           scene:{
            	xaxis: {
                title: '',
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
            	 backgroundcolor: "rgb(200, 200, 230)",
            	 gridcolor: "white",
            	 showbackground: false,
            	 zerolinecolor: "rgb(255, 255, 255)",
            	},
              yaxis: {
                title: '',
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
               backgroundcolor: "rgb(230, 200,230)",
               gridcolor: "white",
               showbackground: false,
               zerolinecolor: "rgb(255, 255, 255)"
              },
              zaxis: {
                title: '',
                titlefont: {
                  family: 'Raleway',
                  size: 18,
                  color: 'lightgrey'
                },
               backgroundcolor: "rgb(230, 230,200)",
               gridcolor: "rgb(255, 255, 255)",
               showbackground: false,
               zerolinecolor: "rgb(255, 255, 255)"
             },
             camera: this.state.eye
           },
            margin: {
              l: 0,
              r: 0,
              b: 0,
              t: 0,
              pad: 40
            }
         } }
       />
       </div>
        <p style={{"fontSize":"50px"}}><strong>{localiseText[this.props.lang]["The power of Performance Routing"]}</strong><br/>
        <span style={{"fontSize":"30px"}}>{localiseText[this.props.lang]["Using AI to turn weather routing into a powerful decarbonisation tool"]}</span><br/>

      </p>

      <p><BsFullscreen style={{cursor:'pointer'}} onClick={this.toggleFullScreen} size={30}/></p>
      <div style={{color: 'white', cursor:'pointer'}} id="toggleLanguage" onClick={this.props.toggleLang}><MdOutlineLanguage size={35}/></div>
      </header>
    </div>
  );
}
}


let datalist1 = [
  {
    x: plotData1[0].x,
    y: plotData1[0].y,
    z: plotData1[0].z,
    type: 'surface',
    hoverinfo: 'text',
    text:["Am","ask","rh"],
    showscale: false,
    colorscale: [
      ['0.0', '#47B5D6'],
      ['0.3', '#3F6FEF'],
      ['0.6', '#9855DD'],
      ['1.0', '#C91A62']
    ],
  }
]

let datalist2 = [
  {
    z: plotData2,
    type: 'surface',
    showscale: false,
    colorscale: [
      ['0.0', '#3F6FEF'],
      ['0.5', '#9855DD'],
      ['1.0', '#C91A62']
    ],
  }
]




export default Intro;
