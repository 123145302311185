import video from './videos/gif2_v1.mp4';
import videoJap from './videos/gif2_v1.mp4';
import video_switch from './videos/gif2_v1_switch.mp4';
import videoJap_switch from './videos/gif2_v1_switch.mp4';

import './App.css';
import React from 'react';
import localiseText from './text.json';
import { MdOutlineLanguage } from 'react-icons/md';

class Solution extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fouling:true,
    };
  }

  componentDidMount(){

  }

  toggleFouling = () => {
      this.setState({
        fouling: !this.state.fouling
      })
  }


  render() {
  return (
    <div className="App">
      <header className="App-header">

      {this.props.lang==="EN" && this.state.fouling &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>
            <source id="map" src={video} type="video/mp4"></source>
        </video>
      }
      {this.props.lang==="EN" && !this.state.fouling &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>>
            <source id="map" src={video_switch} type="video/mp4"></source>
        </video>
      }
      {this.props.lang==="JAP" && this.state.fouling &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>>
            <source id="map" src={videoJap} type="video/mp4"></source>
        </video>
      }
      {this.props.lang==="JAP" && !this.state.fouling &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>>
            <source id="map" src={videoJap_switch} type="video/mp4"></source>
        </video>
      }

      <p><strong>{localiseText[this.props.lang]["Uncovered: your unique, optimised voyage profile"]}</strong></p>
      <div style={{color: 'white', cursor:'pointer'}} id="toggleLanguage" onClick={this.props.toggleLang}><MdOutlineLanguage size={35}/></div>
      <div style={{cursor:'pointer'}} id="toggleFouling" onClick={this.toggleFouling}>{localiseText[this.props.lang][!this.state.fouling?"Remove fouling":"Add fouling"]}</div>
      </header>
    </div>
  );
}
}


export default Solution;
