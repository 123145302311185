import video from './videos/gif1_v2.mp4';
import videoJap from './videos/gif1_v2_jp.mp4';
import loader from './loader.png';

import './App.css';
import React from 'react';
import localiseText from './text.json';
import { MdOutlineLanguage } from 'react-icons/md';

class Map extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      videoPlays:false
    };
  }

  handleVideo = () => {
    this.setState({
      videoPlays: !this.state.videoPlays
    })
  }

  componentDidMount(){

  }

  render() {
  return (
    <div className="App">

      <header className="App-header">

      <div className="loader"><img alt="loading" src={loader}/></div>

      {this.props.lang==="EN" &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>
            <source id="map" src={video} type="video/mp4"></source>
        </video>
      }
      {this.props.lang==="JAP" &&
        <video loop autoPlay muted style={{height: "70vh", width:"70vh"}}>
            <source id="map" src={videoJap} type="video/mp4"></source>
        </video>
      }

      {/*<button onClick={this.handleVideo}>
          {this.state.videoPlays ? "Pause" :
              "Play"}
      </button>*/}

      <p>{localiseText[this.props.lang]["A real voyage sailed in November 2023"]}
      <br/>
      <strong>{localiseText[this.props.lang]["What is the optimised speed plan?"]}</strong></p>

      <div style={{color: 'white', cursor:'pointer'}} id="toggleLanguage" onClick={this.props.toggleLang}><MdOutlineLanguage size={35}/></div>
      </header>
    </div>
  );
}
}


export default Map;
